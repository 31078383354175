import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------
console.log(PATH_DASHBOARD.general.clientPlanListing,"===============>");
const adminMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;

  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "Inventory",
        path: PATH_DASHBOARD.general.inventory,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "Dashboard",
            path: PATH_DASHBOARD.general.inventory,
            icon: ICONS.alerts,
          },
          {
            title: "Live Inventory Restaurant",
            path: PATH_DASHBOARD.general.alerts,
            icon: ICONS.alerts,
          },
          {
            title: "Average Inventory Usage",
            path: PATH_DASHBOARD.general.eodReport,
            icon: ICONS.eodReport,
          },
          {
            title: "ledger",
            path: PATH_DASHBOARD.general.ledger,
            icon: ICONS.ledger,
          },
          {
            title: "overview",
            path: PATH_DASHBOARD.general.overview,
            icon: ICONS.overview,
          },
          {
            title: "metrics",
            path: PATH_DASHBOARD.general.metrics,
            icon: ICONS.metrics,
          },
          {
            title: "history",
            path: PATH_DASHBOARD.general.history,
            icon: ICONS.history,
          },
          {
            title: "insights",
            path: PATH_DASHBOARD.general.insights,
            icon: ICONS.insights,
          },
        ],
      },
      {
        title: "Reports",
        icon: ICONS.analytics,
        children: [
          {
            title: "Collection Sheet from DB",
            path: PATH_DASHBOARD.general.collectionSheet,
            icon: ICONS.grid,
          },
          {
            title: "Container Count List",
            path: PATH_DASHBOARD.general.continerCountList,
            icon: ICONS.grid,
          },
          {
            title: "Collection Agent Report",
            path: PATH_DASHBOARD.general.collectionReport,
          },
          {
            title: "Opt-In Orders",
            path: PATH_DASHBOARD.general.OptinOrderList,
          },

          {
            title: "Opted for Bag Report",
            path: PATH_DASHBOARD.general.optedForBag,
          },
          {
            title: "Dispatch Plan Report",
            path: PATH_DASHBOARD.general.dispatchPlan,
          },
          {
            title: "Restaurant History Report",
            path: PATH_DASHBOARD.general.restaurantStatusHistory,
          },
          {
            title: "Order Report",
            path: PATH_DASHBOARD.general.notScheduled,
            icon: ICONS.grid,
          },
          {
            title: "Packaging Charges Report",
            path: PATH_DASHBOARD.general.packagingReport,
            icon: ICONS.grid,
          },
          {
            title: "Order Dispatch",
            path: PATH_DASHBOARD.general.dispatch,
            icon: ICONS.grid,
          },
          {
            title: "Order Verification",
            path: PATH_DASHBOARD.general.orderVerificationReport,
          },
          {
            title: "Petpooja Report",
            path: PATH_DASHBOARD.general.petPoojaReport,
          },
          {
            title: "Transit Plan Listing",
            path: PATH_DASHBOARD.general.transitPlan,
            icon: ICONS.grid,
          },
          {
            title: "QC Listing",
            path: PATH_DASHBOARD.general.qcListing,
            icon: ICONS.grid,
          },
          {
            title: "Weekly Report",
            path: PATH_DASHBOARD.general.weeklyReport,
          },
        ],
      },

      {
        title: "Order Listing",
        path: PATH_DASHBOARD.general.orderListing,
        icon: ICONS.analytics,
      },
      {
        title: "Ticketing",
        path: PATH_DASHBOARD.general.issues,
        icon: ICONS.alerts,
      },
      {
        title: "Feedback",
        path: PATH_DASHBOARD.general.feedback,
        icon: ICONS.user,
      },
      {
        title: "Invoice Menu",
        icon: ICONS.booking,
        children: [
          {
            title: "Billing Details",
            path: PATH_DASHBOARD.general.cumulativeBillingListing,
            icon: ICONS.analytics,
          },
          // {
          //   title: "Invoice List",
          //   path: PATH_DASHBOARD.general.invoiceBillingListing,
          //   icon: ICONS.analytics,
          // },
          {
            title: "Invoice List 2",
            path: PATH_DASHBOARD.general.invoiceBillingListing2,
            icon: ICONS.analytics,
          },
        ],
      },
      {
        title: "Containers",
        children: [
          {
            title: "Add Container",
            path: PATH_DASHBOARD.general.addContainer,
          },
          {
            title: "SKU List",
            path: PATH_DASHBOARD.general.containersListing,
          },
          {
            title: "Delete Container",
            path: PATH_DASHBOARD.general.deleteContainer,
          },
        ],
      },
      {
        title: "Master Plan",
        icon: ICONS.booking,
        children: [
          {
            title: "Master Plan Listing",
            path: PATH_DASHBOARD.general.masterPlanListing,
            icon: ICONS.grid,
          },
          {
            title: "Create Master Plan",
            icon: ICONS.eodReport,
            path: PATH_DASHBOARD.general.createTransitPlan,
          },
          // {
          //   title: "Create Transit Plan",
          //   icon: ICONS.eodReport,
          //   path: PATH_DASHBOARD.general.createTransitPlan,
          // },
        ],
      },
     
      {
        title: "Transit Plan",
        icon: ICONS.booking,
        children: [
          {
            title: "Transit Plan Listing",
            path: PATH_DASHBOARD.general.transitPlan,
            icon: ICONS.grid,
          },
          // {
          //   title: "Create/Edit Plan",
          //   icon: ICONS.eodReport,
          //   path: PATH_DASHBOARD.general.createForm,
          // },
        ],
      },
      // {
      //   title: "Plan Master",
      //   icon: ICONS.booking,
      //   path: PATH_DASHBOARD.general.planMaster,
      // },
      // Inventory Client Plan
      {
        title:"Client Plan",
        children:[
          {
            title: "Inventory Client Plan",
            path: PATH_DASHBOARD.general.clientPlanListing,
          },
          {
            title: "Everyday Client listing",
            path: PATH_DASHBOARD.general.everydayClientInventoryValues,
          },
        ]
      },

      {
        title:"User",
        children:[
          {
            title: "Add User",
            path: PATH_DASHBOARD.general.addUser,
          },
          {
            title: "User listing",
            path: PATH_DASHBOARD.general.userListing,
          },
          
        ]
      },

      {
        title:"Vehicle",
        children:[
          {
            title: "Add Vehicle",
            path: PATH_DASHBOARD.general.addVehicle,
          },
          {
            title: "Vehicle listing",
            path: PATH_DASHBOARD.general.vehicleListing,
          },
          
        ]
      },

      {
        title:"Client",
        children:[
          {
            title: "Add Client",
            path: PATH_DASHBOARD.general.addClient,
          },
          {
            title: "Client listing",
            path: PATH_DASHBOARD.general.clientListing,
          },
          
        ]
      },

      //Ops Details 
      {
        title: "Ops Details",

        children: [

          {
            title: "Ops Shift Report",
            children: [
              {
                title: "Add Ops Shift Status",
                path: PATH_DASHBOARD.general.addOpsShiftReport,
              },
              {
                title: "Ops Shift Report listing",
                path: PATH_DASHBOARD.general.opsShiftReportListing,
              },
            ],
          },
          {
            title: "Ops QC Report",
            children: [
              {
                title: "Add Ops QC Status",
                path: PATH_DASHBOARD.general.addOpsQCReport,
              },
              {
                title: "Ops QC Report listing",
                path: PATH_DASHBOARD.general.opsQCReportListing,
              },
            ],
          },
          {
            title: "Faclity Man Power",
            children: [
              {
                title: "Add Facility Man Power Count",
                path: PATH_DASHBOARD.general.addFacilityManPowerCount,
              },
              {
                title: "Facility Man Power listing",
                path: PATH_DASHBOARD.general.facilityManPowerCountListing,
              },
            ],
          },
          {
            title: "Water Efficiency",
            children: [
              {
                title: "Add Water Efficiency",
                path: PATH_DASHBOARD.general.addWaterEfficiency,
              },
              {
                title: "Water Efficiency listing",
                path: PATH_DASHBOARD.general.waterEfficiencyListing,
              },
            ],
          },
          {
            title: "Client Escalation",
            children: [
              {
                title: "Add Client Escalation",
                path: PATH_DASHBOARD.general.addClientEscalation,
              },
              {
                title: "Client Escalation listing",
                path: PATH_DASHBOARD.general.clientEscalationListing,
              },
            ],
          },
          {
            title: "Escalation Type",
            children: [
              {
                title: "Add Escalation Type",
                path: PATH_DASHBOARD.general.addEscalationType,
              },
              {
                title: "Escalation Type listing",
                path: PATH_DASHBOARD.general.escalationTypeListing,
              },
            ],
          },
        ],
      },

      {
        title: "Client SKU",
            children: [
              {
                title: "Add Client SKU Mapping",
                path: PATH_DASHBOARD.general.addClientSKUMap,
              },
              {
                title: "Client SKU Map  listing",
                path: PATH_DASHBOARD.general.clientSKUListing,
              },
            ],
      },  

      //Weekly Review
      {
        title: "Weekly Revenue",

        children: [

          {
            title: "Monthly Projections",
            children: [
              {
                title: "Add Monthly Projected Costing",
                path: PATH_DASHBOARD.general.addProjectCost,
              },
              {
                title: "Monthly Projected Cost  listing",
                path: PATH_DASHBOARD.general.projectCostListing,
              },
            ],
          },
          {
            title: "Review Cost Type",
            children: [
              {
                title: "Add Review Cost",
                path: PATH_DASHBOARD.general.addReviewCost,
              },
              {
                title: "Review Cost  listing",
                path: PATH_DASHBOARD.general.reviewCostTypeListing,
              },
            ],
          },
          {
            title: "Revenue",
            children: [
              {
                title: "Add Revenue",
                path: PATH_DASHBOARD.general.addRevenue,
              },
              {
                title: "Revenue listing",
                path: PATH_DASHBOARD.general.revenueListing,
              },
            ],
          },
          {
            title: "Facility Cost",
            children: [
              {
                title: "Add Facility Cost",
                path: PATH_DASHBOARD.general.addFacilityCost,
              },
              {
                title: "Facility Cost listing",
                path: PATH_DASHBOARD.general.facilitycostListing,
              },
            ],
          },
          {
            title: "Logistic Cost",
            children: [
              {
                title: "Add Logistic Cost",
                path: PATH_DASHBOARD.general.AddLogistic,
              },
              {
                title: "Logistic listing",
                path: PATH_DASHBOARD.general.logisticListing,
              },
            ],
          },
        ],
      },
      {
        title: "Inventory Count",
        children: [
          {
            title: "Live Stock Count",
            children: [
              {
                title: "Client Stock",
                path: PATH_DASHBOARD.general.liveClientStockListing,
              },
              {
                title: "Facility Stock",
                path: PATH_DASHBOARD.general.liveFacilityStockListing,
              },
              {
                title: "City Stock",
                path: PATH_DASHBOARD.general.liveCityStockListing,
              },
            ],
          },
          {
            title: "Sent Inventory",
            children: [
              {
                title: "Sent Transit Plan",
                path: PATH_DASHBOARD.general.inventoryTransitSentListing,
              },
              {
                title: "Sent Inventory listing",
                path: PATH_DASHBOARD.general.inventorySentListing,
              },
            ],
          },
          {
            title: "Received Inventory",
            children: [
              {
                title: "Received Transit Plan",
                path: PATH_DASHBOARD.general.inventoryTransitReceivedListing,
              },
              {
                title: "Received Inventory listing",
                path: PATH_DASHBOARD.general.inventoryReceivedListing,
              },
            ],
          },
          {
            title: "Facility EOD Inventory",
            children: [
              {
                title: "Add EOD Inventory",
                path: PATH_DASHBOARD.general.inventoryEOD,
              },
              {
                title: "EOD Inventory listing",
                path: PATH_DASHBOARD.general.inventoryEODListing,
              },
            ],
          },
          {
            title: "Client EOD Inventory",
            children: [
              {
                title: "Add EOD Inventory",
                path: PATH_DASHBOARD.general.inventoryClientEOD,
              },
              {
                title: "EOD Inventory listing",
                path: PATH_DASHBOARD.general.inventoryClientEODListing,
              },
            ],
          },
          {
            title: "EOW Inventory",
            children: [
              {
                title: "Add EOW Inventory",
                path: PATH_DASHBOARD.general.inventoryEOW,
              },
              {
                title: "EOW Inventory listing",
                path: PATH_DASHBOARD.general.inventoryEOWListing,
              },
            ],
          },
          // {
          //   title: "Client Count",
          //   children: [
          //     {
          //       title: "Add Client Count",
          //       path: PATH_DASHBOARD.general.inventoryClient,
          //     },
          //   ],
          // },
          // {
          //   title: "B2B Inventory",
          //   children: [
          //     {
          //       title: "ADD B2B Inventory",
          //       path: PATH_DASHBOARD.general.inventoryAddB2B,
          //     },
          //   ],
          // },
          {
            title: "Purchase Inventory",
            children: [
              {
                title: "ADD Purchase Inventory",
                path: PATH_DASHBOARD.general.inventoryPurchase,
              },
              {
                title: "Purchase Inventory Listing",
                path: PATH_DASHBOARD.general.inventoryPurchaseListing,
              },
              {
                title: "Purchase Movement Inventory",
                path: PATH_DASHBOARD.general.inventoryPurchaseMovement,
              },
            ],
          },
        ],
      },
    ],
  },
];

export { adminMenu };
